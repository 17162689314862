<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-card
        @click="open"
        style="width: 150px; height: 80px"
        :style="{ opacity: bookmark.disabled ? 0.5 : 1 }"
        class="d-flex flex-column ma-2"
        v-bind="attrs"
        v-on="on"
        elevation="4"
        :title="`${bookmark.title} - ${bookmark.description}`"
      >
        <v-card-title
          class="
            pa-0
            pl-2
            pr-2
            text-subtitle-2 text-no-wrap text-truncate
            d-block
          "
        >
          {{ bookmark.title }}
        </v-card-title>
        <v-card-text
          class="d-flex flex-column justify-center align-center flex-grow-1"
        >
          <img
            v-if="bookmark.image"
            :src="bookmark.image"
            :alt="bookmark.title"
            height="36"
            width="36"
          />
          <v-icon v-else size="36">{{bookmark.icon || 'mdi-web'}}</v-icon>
        </v-card-text>
      </v-card>
    </template>
    <div>
      <div>
          <div v-if="bookmark.disabled">
        <strong ><u>Bookmark Disabled</u></strong>

          </div>
        <strong>{{ bookmark.title }}</strong>
      </div>
      <div v-html="description"></div>
    </div>
  </v-tooltip>
</template>

<script>
import Bowser from 'bowser'
const browser = Bowser.getParser(window.navigator.userAgent);

export default {
  props: ["bookmark"],
  data(){
      return {
          isIE: browser.name=="Internet Explorer"
      }
  },
  computed: {
    description() {
      return (this.bookmark.description || "").replace(/\n/gim, "<br/>");
    },
  },
  methods: {
    copyToClipboard(){
          navigator.clipboard.writeText(this.bookmark.url);
          let altBrowser = this.isIE ? 'Chrome' : 'Internet Explorer'
          alert(`Please open this bookmark in ${altBrowser}. It has been copied to your clipboard`)
    },
    open() {
        if  (this.isIE && !this.bookmark.ieOnly){
            return this.copyToClipboard()
        } else if (!this.isIE && this.bookmark.ieOnly){
            return this.copyToClipboard()
        } else if (this.bookmark.disabled) {
        return;
      }
      window.open(this.bookmark.url);
      // browser.windows.create({"url": this.bookmark.url, "incognito": true});
    },
  },
};
</script>

<style>
</style>