<template>
  <div class="">
    <div>
      <v-form class="d-flex justify-center">
        <v-text-field
          class="mr-10 ml-10 pr-10 pl-10"
          v-model.trim="search"
          clearable
          autofocus
          background-color="#FFF"
          height="50px"
          rounded
          placeholder="Search Bookmarks"
          solo
        ></v-text-field>
      </v-form>
    </div>
    <div>
      <template v-if="!Object.keys(filteredBookmarks).length">
        <div>No Results</div>
      </template>
      <template v-for="(bookmarkCollection, collection) in filteredBookmarks">
        <div
          :key="collection"
          class=""
          v-if="bookmarkCollection.bookmarks.length"
        >
          <div class="d-flex justify-center">
            <strong>
              {{ collection }}
            </strong>
          </div>
          <draggable
            class="d-flex flex-wrap justify-center"
            v-model="bookmarkCollection.bookmarks"
            @end="reorder(collection)"
            :disabled="false"
          >
            <div
              @click="clearSearch"
              v-for="bookmark in bookmarkCollection.bookmarks"
              :key="bookmark.id"
            >
              <Bookmark :bookmark="bookmark" />
            </div>
          </draggable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Bookmark from "../components/Bookmark.vue";
import draggable from "vuedraggable";
import firebase from "../firebase";
const db = firebase.firestore()


function escapeRegex(string) {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export default {
  data() {
    return {
      search: "",
    };
  },
  methods: {
    async reorder(collection){
      // let toast = this.$toasted.show('Saving Order', {
      //   fullWidth: false,
      //   iconPack: 'mdi',
      //   icon: 'content-save',
      //   type: 'success',
      //   singleton: true,
      //   position: 'bottom-center',
      //   theme: "bubble", 

      // })
      this.$store.commit('SET_SAVING', true)
      let result = this.bookmarks[collection]
      console.log('REORDER', result)
      console.log({result})
      let doc = await db.collection("bookmarks").doc(collection)
      await doc.set(result)
      this.$store.commit('SET_SAVING', false)
    //  toast.goAway()
    },
    clearSearch() {
      this.search = "";
    },
  },
  computed: {
    ...mapState(["bookmarks"]),
    filteredBookmarks() {
      if (!this.search) {
        console.log("NO searvh");
        return this.bookmarks;
        // this.bookmarks.forEach((bookmark) => {
        //   let category = bookmark.category || "default";
        //   groupedBookmarks[category] = groupedBookmarks[category] || [];
        //   groupedBookmarks[category].push(bookmark);
        // });
        // return groupedBookmarks;
      }
      let result = {};
      Object.keys(this.bookmarks).forEach((collection) => {
        let bookmarks = this.bookmarks[collection].bookmarks.filter(
          (bookmark) => {
            console.log(escapeRegex(this.search.toLowerCase()))
            let pattern = new RegExp(escapeRegex(this.search.toLowerCase()),'igm')
            return `${bookmark.title} ${bookmark.collection} ${bookmark.description} ${bookmark.url}`
              .toLowerCase()
              .match(pattern);
          }
        );
        if (bookmarks.length) {
          result[collection] = {
            bookmarks,
          };
        }
      });

      return result;
    },
  },
  components: {
    Bookmark,
    draggable,
  },
};
</script>

<style>
</style>